import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {rootAPI} from "../../app/api";
import { Job, Status, jobAdapter } from "../job/jobAPI";

interface Repo {
  id: number;
  integ_id: number;
  owner: string;
  name: string;
  status: Status;
}

interface OriginRepo{
  id: number;
  owner: string;
  name: string;
}

export const repoAdapter = createEntityAdapter<Repo>();

export const repoAPI = rootAPI.injectEndpoints({
  endpoints: (builder) => ({
    repo: builder.query<Repo, number> ({
      query: (repoId: number) => `repo/${repoId}/detail`,
    }),
    jobs: builder.query<EntityState<Job>, number>({
      query: (repoId: number) => `repo/${repoId}/jobs`,
      transformResponse(response: Job[]) {
        return jobAdapter.addMany(jobAdapter.getInitialState(), response)
      },
      providesTags: (_, __, repoId) => [{type: "Jobs", repoId}],
    }),
    search: builder.query<OriginRepo[], {query:string, integId:number}>({
      query: (arg) =>`integ/${arg.integId}/search?query=${arg.query}`,
    }),
  }),
});

export const { useRepoQuery, useJobsQuery, useSearchQuery } = repoAPI;

export type { Repo };
