import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import integReducer from "../features/integ/integSlice";
import navReducer from "../features/subnav/navSlice";
import { loadState, saveState } from "./persistent";
import {rootAPI} from "./api";

const preloadedState = loadState();

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    integ: integReducer,
    subnav: navReducer,
    [rootAPI.reducerPath]: rootAPI.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    rootAPI.middleware,
  ),
  preloadedState
});

store.subscribe(()=>{
  saveState(store.getState());
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
