import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface Repo {
  name: string;
  id: number;
}

interface Module {
  name: string;
  path: string;
}

const RepoModule: Module = {
  name: "Repository",
  path: "/",
};
const IntegModule: Module = {
  name: "Integration",
  path: "/integ",
};

interface SubNavState {
  module: Module;
  repo: Repo | null;
  job: number | null;
}

const initialState: SubNavState = {
  module: IntegModule,
  repo: null,
  job: null,
};

export const navSlice = createSlice({
  name: "subnav",
  initialState,
  reducers: {
    updateSubNav: (state, action: PayloadAction<SubNavState>) => {
      state.module = action.payload.module;
      state.job = action.payload.job;
      state.repo = action.payload.repo;
    },
  },
});

export const { updateSubNav } = navSlice.actions;
export const selectSubNav = (state: RootState) => state.subnav;
export default navSlice.reducer;
export { RepoModule, IntegModule };

export type { SubNavState, Module };
