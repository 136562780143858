import { Link as RouterLink } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import { colors } from "../../colors";
import { selectSubNav } from "./navSlice";
import { useAppSelector } from "../../app/hooks";

function SubNav() {
  const subNav = useAppSelector(selectSubNav);

  var links: Array<JSX.Element> = [
    <Link
      underline="hover"
      color="inherit"
      component={RouterLink}
      to={subNav.module.path}
      sx={{ fontSize: "1.1rem" }}
      key="homepage"
    >
      {subNav.module.name}
    </Link>,
  ];

  if (subNav.repo !== null) {
    links.push(
      <Link
        underline="hover"
        color="inherit"
        component={RouterLink}
        to={`/repo/${subNav.repo.id}/jobs`}
        sx={{ fontSize: "1.1rem" }}
        key="repo"
      >
        {subNav.repo.name}
      </Link>
    );

    if (subNav.job !== null) {
      links.push(
        <Typography color="text.primary" key="job">
          Job-{subNav.job}
        </Typography>
      );
    }
  }

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{
        marginTop: "10px",
        marginBottom: "10px",
        color: colors.cyan["400"],
      }}
      separator={<NavigateNext fontSize="small" />}
    >
      {links}
    </Breadcrumbs>
  );
}

export { SubNav };
