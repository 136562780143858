import type { RootState } from "./store";

export const loadState = () => {
  try {
    const lastInteg = localStorage.getItem("curInteg");
    return {
      integ: {
        selected: lastInteg !== null ? Number(lastInteg) : -1,
      },
    };
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: RootState) => {
  try {
    localStorage.setItem("curInteg", state.integ.selected.toString());
  } catch (err) {
    console.log(err);
  }
};
