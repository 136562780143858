import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectInteg } from "../integ/integSlice";
import { useSearchQuery } from "./repoAPI";
import { useNavigate } from "react-router-dom";
import {
  useAllQuery,
  useConnectRepoMutation,
  useConnectedReposQuery,
} from "../integ/integAPI";
import { renderStatus } from "../job/jobList";
import { ChangeEvent, useEffect, useState } from "react";
import { RepoModule, updateSubNav } from "../subnav/navSlice";

function RepoList() {
  const curInteg = useAppSelector(selectInteg);
  const { data } = useConnectedReposQuery(curInteg, {
    skip: curInteg === -1,
  });
  const { data: integData } = useAllQuery();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    dispatch(
      updateSubNav({
        module: RepoModule,
        repo: null,
        job: null,
      })
    );
  }, []);

  return (
    <>
      <br />
      <Box>
        <Button
          variant="outlined"
          onClick={() => {
            setOpen(true);
          }}
        >
          Add
        </Button>
      </Box>
      <TableContainer>
        <Table aria-label="repos">
          <colgroup>
            <col style={{ width: "20%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "20%" }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>Repository</TableCell>
              <TableCell>Integ</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.ids.map((id) => {
              const repo = data?.entities[id]!;
              return (
                <TableRow
                  key={repo.id}
                  hover={true}
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigate(`/repo/${repo.id}/jobs`)}
                >
                  <TableCell>
                    <Box fontFamily="Monospace">
                      {repo.owner}/{repo.name}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {integData?.entities[repo.integ_id]?.name}
                  </TableCell>
                  <TableCell>{renderStatus(repo.status)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {curInteg !== -1 ? (
        <AddDialog
          open={isOpen}
          closeDialog={() => setOpen(false)}
          integId={curInteg}
        />
      ) : (
        <></>
      )}
    </>
  );
}

interface AddProp {
  open: boolean;
  closeDialog: () => void;
  integId: number;
}

const DEBOUNCE_DELAY = 300;

function AddDialog(props: AddProp) {
  const [query, setQuery] = useState("");

  const [debounceId, setDebounceId] = useState<number | null>(null);
  const [debouncedQuery, setDebouncedQuery] = useState(query);

  const debounceChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setQuery(evt.target.value);

    if (debounceId !== null) {
      clearTimeout(debounceId!!);
    }

    let timeoutId = window.setTimeout(() => {
      setDebouncedQuery(evt.target.value);
    }, DEBOUNCE_DELAY);
    setDebounceId(timeoutId);
  };

  const { data, isLoading } = useSearchQuery({
    query: debouncedQuery,
    integId: props.integId,
  });

  const [connectRepo] = useConnectRepoMutation();

  return (
    <>
      <Dialog
        open={props.open}
        fullWidth={true}
        onBackdropClick={props.closeDialog}
      >
        <DialogContent>
          <TextField
            value={query}
            label={"Search"}
            fullWidth={true}
            variant="standard"
            onChange={debounceChange}
            margin="dense"
            autoFocus={true}
          />
          {isLoading ? <LinearProgress /> : <></>}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Repository</TableCell>
                <TableCell>Operation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.slice(0, 10).map((repo, idx) => (
                <TableRow key={idx}>
                  <TableCell>{repo.id}</TableCell>
                  <TableCell>
                    <Box fontFamily="Monospace">
                      {repo.owner}/{repo.name}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      onClick={(_) => {
                        connectRepo({
                          integId: props.integId,
                          repo: `${repo.owner}/${repo.name}`,
                        });
                      }}
                    >
                      Connect
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.closeDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export { RepoList };
