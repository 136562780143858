export const colors = {
  turquoise: {
    '50':  '#eef5f4',
    '100': '#cdf0f3',
    '200': '#95e6e4',
    '300': '#5cccc4',
    '400': '#24ae9d',
    '500': '#199377',
    '600': '#177c5e',
    '700': '#16604b',
    '800': '#114238',
    '900': '#0c292a',
  },
  navy: {
    '50':  '#f4f8f9',
    '100': '#dbf0fa',
    '200': '#b4dff5',
    '300': '#83bfe7',
    '400': '#509bd5',
    '500': '#3c7ac4',
    '600': '#335fae',
    '700': '#2a478b',
    '800': '#1e3164',
    '900': '#121e41',
  },
  cyan: {
    '50':  '#f6f9fa',
    '100': '#e2f0fb',
    '200': '#c2dbf8',
    '300': '#97b9ee',
    '400': '#6c92e0',
    '500': '#566fd5',
    '600': '#4753c3',
    '700': '#373ea0',
    '800': '#272b74',
    '900': '#161b4a',
  },
  purple: {
    '50':  '#f9fafb',
    '100': '#f1effa',
    '200': '#e2d4f5',
    '300': '#c7ade6',
    '400': '#b482d5',
    '500': '#9c5ec6',
    '600': '#8242af',
    '700': '#62328a',
    '800': '#44235f',
    '900': '#271638',
  },
  purple2: {
    '50':  '#fbfbfb',
    '100': '#f5eff7',
    '200': '#ecd1f0',
    '300': '#d8a9dd',
    '400': '#cc7cc5',
    '500': '#b758b1',
    '600': '#9c3d94',
    '700': '#772e71',
    '800': '#53204c',
    '900': '#31152a',
  },
  cerise: {
    '50':  '#fcfbfa',
    '100': '#fbefea',
    '200': '#f6d0d5',
    '300': '#eba4af',
    '400': '#e67585',
    '500': '#d75263',
    '600': '#be3846',
    '700': '#972a34',
    '800': '#6c1d23',
    '900': '#431314',
  },
  cocoa: {
    '50':  '#fcfbf7',
    '100': '#faefd5',
    '200': '#f4d7aa',
    '300': '#e4af76',
    '400': '#d4824a',
    '500': '#bd612c',
    '600': '#a0471c',
    '700': '#7c3517',
    '800': '#562512',
    '900': '#37170c',
  },
  gold: {
    '50':  '#fbfaf3',
    '100': '#f9efb8',
    '200': '#f1dd7c',
    '300': '#dbb94b',
    '400': '#bb8f28',
    '500': '#9c7014',
    '600': '#7f560d',
    '700': '#61410c',
    '800': '#422d0a',
    '900': '#2d1c08',
  },
  yellow: {
    '50':  '#faf9f1',
    '100': '#f6efb3',
    '200': '#ebe073',
    '300': '#cdbf44',
    '400': '#9d9823',
    '500': '#7a7a10',
    '600': '#61620b',
    '700': '#4b4a0a',
    '800': '#34330a',
    '900': '#231f08',
  },
  grass: {
    '50':  '#f4f7f2',
    '100': '#e6efde',
    '200': '#c5e4b6',
    '300': '#8fc882',
    '400': '#49a752',
    '500': '#338b2f',
    '600': '#2a7421',
    '700': '#25591c',
    '800': '#1b3d17',
    '900': '#132613',
  },
};
