import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import {rootAPI} from "../../app/api";
import { repoAdapter, Repo } from "../repo/repoAPI";

type Integ = IntegIO & {
  id: number;
  user_id: number;
}

type IntegIO = {
  type: string;
  url: string;
  username: string;
  name: string;
  access_token: string;
}

const integAdapter = createEntityAdapter<Integ>();

export const integAPI = rootAPI.injectEndpoints({
  endpoints: (builder) => ({
    all: builder.query<EntityState<Integ>, void>({
      query: () => `integ/all`,
      transformResponse(response: Integ[]) {
        return integAdapter.addMany(integAdapter.getInitialState(), response)
      },
      providesTags: (result) =>  result
          ? [...result?.ids.map(( id ) => ({ type: 'Integ' as const, id })), 'Integ']
          : ['Integ'],
    }),
    connectedRepos: builder.query<EntityState<Repo>, number>({
      query: (integId: number) => `integ/${integId}/connected`,
      transformResponse(response: Repo[]) {
        return repoAdapter.addMany(repoAdapter.getInitialState(), response)
      },
      providesTags: (_, __, integId) => ['Repos', {type: 'Repos', integId}],
    }),
    connectRepo: builder.mutation<void, {integId: number, repo: string}>({
      query: (arg) => ({
        url: `integ/${arg.integId}/connect?repo=${arg.repo}`,
        method: 'POST',
      }),
      invalidatesTags: (_, __, arg) => [{type: 'Repos', integId: arg.integId}]
    }),
    addInteg: builder.mutation<void, IntegIO>({
      query: (integ) => ({
        url: `integ/add`,
        method: 'POST',
        body: integ,
      }),
      invalidatesTags: ['Integ']
    })
  })
});

export const {
  useAllQuery, useAddIntegMutation, useConnectRepoMutation, useConnectedReposQuery
} = integAPI;
export type {IntegIO, Integ};
