import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { colors } from "./colors";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Drawer } from "./features/drawer/Drawer";
import { Header } from "./features/header/Header";
import { RepoList } from "./features/repo/RepoList";
import { Container, Divider, Hidden, Toolbar } from "@mui/material";
import { JobDetail } from "./features/job/jobDetail";
import { JobList } from "./features/job/jobList";
import { useAllQuery } from "./features/integ/integAPI";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { useEffect } from "react";
import { select, selectInteg } from "./features/integ/integSlice";
import { SubNav } from "./features/subnav/subNav";
import { IntegList } from "./features/integ/IntegList";

const theme = createTheme({
  palette: {
    primary: {
      main: colors.navy["500"],
    },
  },
});

function App() {
  const { data, isSuccess } = useAllQuery();
  const curInteg = useAppSelector(selectInteg);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      isSuccess &&
      curInteg === -1 &&
      data?.ids.length &&
      data.ids.length > 0
    ) {
      dispatch(select(data.ids[0] as number));
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div style={{ display: "flex" }}>
          <Header />
          <Hidden smDown>
            <Drawer />
          </Hidden>
          <Container maxWidth="xl" sx={{ paddingTop: "20px" }}>
            <Toolbar />
            <SubNav />
            <Divider
              light={true}
              sx={{ marginBottom: "5px", borderColor: colors.cyan["100"] }}
            />
            <Routes>
              <Route path="/" element={<RepoList />} />
              <Route path="/job/:jobId" element={<JobDetail />} />
              <Route path="/repo/:repoId/jobs" element={<JobList />} />
              <Route path="/integ" element={<IntegList />} />
            </Routes>
          </Container>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
