import { Divider } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { RepoModule, updateSubNav } from "../subnav/navSlice";
import { Status, useJobQuery, useLogQuery } from "./jobAPI";

function JobDetail() {
  const { jobId } = useParams();
  const { data } = useLogQuery(Number(jobId));
  const { data: job, isSuccess } = useJobQuery(Number(jobId));

  const dispatch = useAppDispatch();

  useEffect(() => {
    // prevent subNav screen flash
    if (job !== undefined) {
      dispatch(
        updateSubNav({
          module: RepoModule,
          repo: {
            id: Number(job.repo_id),
            name: `${job.owner}/${job.repo}`,
          },
          job: Number(jobId),
        })
      );
    }
  }, [job]);

  let Logs: any = "Loading";
  let isRunning = job?.status == Status.Running;

  if (isSuccess) {
    // if finished then use normal log
    Logs = data?.content.tasks.map((task, ti) => (
      <Box key={ti}>
        <h3>
          Task-{ti}: {task.task_name}
        </h3>
        <Divider />
        {task.stages.map((stage, si) => (
          <Box key={si}>
            <h4>
              Stage-{si}: {stage.name}
            </h4>
            <Box
              sx={{
                backgroundColor: "#eee",
                padding: "10px 20px",
                borderRadius: "10px",
                whiteSpace: "pre-wrap",
                wordBreak: "break-all",
              }}
              component="pre"
            >
              {stage.logs.map((line) => line.content).join("\n")}
            </Box>
          </Box>
        ))}
      </Box>
    ));
  }

  return <>{isSuccess ? Logs : "Not Found"}</>;
}

export { JobDetail };
