import {
  CancelOutlined,
  CheckCircleOutlined,
  DoNotDisturbOnOutlined,
  Replay,
  WatchLaterOutlined,
} from "@mui/icons-material";
import {
  CircularProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useJobsQuery, useRepoQuery } from "../repo/repoAPI";
import { Job, Status, useRerunMutation } from "../job/jobAPI";
import { useAppDispatch } from "../../app/hooks";
import { RepoModule, updateSubNav } from "../subnav/navSlice";
import { useEffect, useState } from "react";

function JobList() {
  const { repoId } = useParams();
  const { data } = useJobsQuery(Number(repoId));

  const { data: repo } = useRepoQuery(Number(repoId));

  const [now, setNow] = useState(Math.floor(Date.now()));

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [rerunJob] = useRerunMutation();

  useEffect(() => {
    if (repo !== undefined) {
      dispatch(
        updateSubNav({
          module: RepoModule,
          repo: {
            id: repo.id,
            name: `${repo.owner}/${repo.name}`,
          },
          job: null,
        })
      );
    }
  }, [repo]);

  useEffect(() => {
    let timer = setTimeout(() => setNow(Math.floor(Date.now())), 1000);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <TableContainer>
      <Table aria-label="jobs">
        <colgroup>
          <col style={{ width: "10%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "20%" }} />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>Branch</TableCell>
            <TableCell>Commit</TableCell>
            <TableCell>Author</TableCell>
            <TableCell>Message</TableCell>
            <TableCell>Duration</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Operation</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.ids.map((id) => {
            const job = data?.entities[id]!;
            return (
              <TableRow
                key={job.id}
                hover={true}
                sx={{ cursor: "pointer" }}
                onClick={() => navigate(`/job/${job.id}`)}
              >
                <TableCell>{renderStatus(job.status)}</TableCell>
                <TableCell>{job.id}</TableCell>
                <TableCell>{job.branch}</TableCell>
                <TableCell>
                  <Link
                    href={job.url}
                    target="_blank"
                    onClick={preventPropagation}
                    underline="hover"
                  >
                    {job.commit_id.substring(0, 6)}
                  </Link>
                </TableCell>
                <TableCell>{job.author}</TableCell>
                <TableCell>{job.message}</TableCell>
                <TableCell>{renderDuration(job, now)}</TableCell>
                <TableCell>
                  {new Date(job.created_at).toLocaleString("zh-cn")}
                </TableCell>
                <TableCell>
                  <Replay
                    onClick={(evt) => {
                      preventPropagation(evt);
                      rerunJob({
                        jobId: job.id,
                        repoId: Number(repoId),
                      });
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function renderStatus(status: Status) {
  switch (status) {
    case Status.Waiting:
      return <WatchLaterOutlined sx={{ color: "gray" }} />;
    case Status.Pending:
      return <WatchLaterOutlined sx={{ color: "yellow" }} />;
    case Status.Running:
      return <CircularProgress size={20} />;
    case Status.Success:
      return <CheckCircleOutlined sx={{ color: "green" }} />;
    case Status.Failed:
      return <CancelOutlined sx={{ color: "red" }} />;
    case Status.Unknown:
      return <DoNotDisturbOnOutlined sx={{ color: "gray" }} />;
  }
}

function renderDuration(job: Job, now: number): string {
  if (job.status == Status.Running) {
    return durationStr((now - job.start_time) / 1000);
  } else {
    return durationStr(job.duration);
  }
}

function durationStr(duration: number): string {
  let durationStr = "";
  if (duration > 3600) {
    durationStr += `${Math.floor(duration / 3600)}h `;
  }
  if (duration > 60) {
    durationStr += `${Math.floor((duration % 3600) / 60)}m `;
  }
  durationStr += `${Math.floor(duration % 60)}s`;
  return durationStr;
}

function preventPropagation(evt: any) {
  evt.stopPropagation();
}

export { JobList, renderStatus };
