import { Link } from "react-router-dom";
import { AppBar, Button, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useGetUserQuery } from "./headerAPI";
import logo from "./logo.png";

const logoSize = "40px";

function Header() {
  const loginRedirect = () => {
    let path = `/v1/gitea/login`;
    window.location.href = path;
  };

  const { data, isSuccess } = useGetUserQuery();

  let user;
  if (!isSuccess) {
    user = (
      <Button variant="text" sx={{ color: "white" }} onClick={loginRedirect}>
        Login
      </Button>
    );
  } else {
    user = <Box>{data.full_name}</Box>;
  }

  return (
    <AppBar
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      elevation={2}
    >
      <Toolbar>
        <Link
          to="/"
          style={{ color: "inherit", textDecoration: "none", display: "flex" }}
        >
          <img src={logo} alt="logo" style={{ width: logoSize }} />
          <Typography
            variant="h6"
            noWrap
            sx={{
              flexGrow: 1,
              fontWeight: "bold",
              lineHeight: logoSize,
              alignItems: "center",
              marginLeft: "10px",
            }}
          >
            Jarvis
          </Typography>
        </Link>
        <Box style={{ flex: 1 }} />
        {user}
      </Toolbar>
    </AppBar>
  );
}

export { Header };
