import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { RootState } from '../../app/store';

export interface IntegState {
  selected: number;
}

const initialState: IntegState = {
  selected: -1,
};

export const integSlice = createSlice({
  name: "integ",
  initialState,
  reducers: {
    select: (state, action: PayloadAction<number>) => {
      state.selected = action.payload;
    }
  }
});

export const { select } = integSlice.actions;

export const selectInteg = (state: RootState) => state.integ.selected;

export default integSlice.reducer;
