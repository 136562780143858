import {
  Divider,
  Drawer as MuiDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Toolbar,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { CloudQueue, GitHub, VideoLabel } from "@mui/icons-material";
import { colors } from "../../colors";
import { useAllQuery } from "../integ/integAPI";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { select, selectInteg } from "../integ/integSlice";

const drawerWidth = 305;

function Drawer() {
  const { data } = useAllQuery();
  const curInteg = useAppSelector(selectInteg);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleChange = (evt: SelectChangeEvent) => {
    navigate(`/`);
    dispatch(select(evt.target.value as unknown as number));
  };

  return (
    <MuiDrawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        [`& .MuiDrawer-paper`]: { width: drawerWidth },
      }}
    >
      <Toolbar />
      <List aria-label="menu" sx={{ paddingTop: 0 }}>
        <ListItem
          sx={{
            paddingY: "15px",
            backgroundColor: colors.navy["50"],
          }}
        >
          <ListItemIcon sx={{ color: "inherit" }}>
            <GitHub />
          </ListItemIcon>
          <Select
            variant="standard"
            sx={{
              [`&:before`]: { borderBottom: "none !important" },
              [`&>div,input`]: {
                paddingLeft: "30px !important",
                paddingRight: "40px !important",
              },
              color: "inherit",
            }}
            value={curInteg === -1 ? "" : (curInteg as unknown as string)}
            onChange={handleChange}
          >
            {data?.ids.map((id) => {
              const integ = data?.entities[id];
              return (
                <MenuItem key={integ!.id} value={integ!.id}>
                  {integ!.name}
                </MenuItem>
              );
            })}
          </Select>
        </ListItem>
        <Divider />
        <Link to="/">
          <ListItem button>
            <ListItemIcon>
              <VideoLabel />
            </ListItemIcon>
            <ListItemText primary="Repository" />
          </ListItem>
        </Link>
        <Link to="/integ">
          <ListItem button>
            <ListItemIcon>
              <CloudQueue />
            </ListItemIcon>
            <ListItemText primary="Integration" />
          </ListItem>
        </Link>
      </List>
    </MuiDrawer>
  );
}

export { Drawer };
