import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import { IntegModule, updateSubNav } from "../subnav/navSlice";
import { useAddIntegMutation, useAllQuery } from "./integAPI";

function IntegList() {
  const { data } = useAllQuery();
  const [isOpen, setOpen] = useState(false);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      updateSubNav({
        module: IntegModule,
        repo: null,
        job: null,
      })
    );
  });

  return (
    <>
      <br />
      <Box>
        <Button
          variant="outlined"
          onClick={() => {
            setOpen(true);
          }}
        >
          Add
        </Button>
      </Box>
      <TableContainer>
        <Table aria-label="integs">
          <colgroup>
            <col style={{ width: "20%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "20%" }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>URL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.ids.map((id) => {
              const integ = data?.entities[id]!;
              return (
                <TableRow key={integ.id.toString()} hover={true}>
                  <TableCell>{integ.name}</TableCell>
                  <TableCell>{integ.type}</TableCell>
                  <TableCell>{integ.url}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <AddDialog open={isOpen} closeDialog={() => setOpen(false)} />
    </>
  );
}

interface AddProp {
  open: boolean;
  closeDialog: () => void;
}

function AddDialog(props: AddProp) {
  const [integ, setInteg] = useState({
    name: "",
    type: "",
    url: "",
    access_token: "",
    username: "",
  });

  const [addInteg, { isSuccess, reset }] = useAddIntegMutation();
  if (isSuccess) {
    reset();
    setInteg({
      name: "",
      type: "",
      url: "",
      access_token: "",
      username: "",
    });
    props.closeDialog();
  }

  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      onBackdropClick={props.closeDialog}
    >
      <DialogContent>
        <TextField
          value={integ.name}
          label="Name"
          fullWidth={true}
          variant="standard"
          onChange={(e) => {
            setInteg({ ...integ, name: e.target.value });
          }}
          margin="dense"
        />
        <TextField
          value={integ.type}
          select
          label="Type"
          fullWidth={true}
          variant="standard"
          onChange={(e) => {
            setInteg({ ...integ, type: e.target.value });
          }}
          margin="dense"
        >
          <MenuItem value="gitea">Gitea</MenuItem>
          <MenuItem value="gitlab">Gitlab</MenuItem>
        </TextField>
        <TextField
          value={integ.url}
          label="URL"
          fullWidth={true}
          variant="standard"
          onChange={(e) => {
            setInteg({ ...integ, url: e.target.value });
          }}
          margin="dense"
        />
        <TextField
          value={integ.username}
          label="Username"
          fullWidth={true}
          variant="standard"
          onChange={(e) => {
            setInteg({ ...integ, username: e.target.value });
          }}
          margin="dense"
        />
        <TextField
          value={integ.access_token}
          label="Access Token"
          fullWidth={true}
          variant="standard"
          type="password"
          onChange={(e) => {
            setInteg({ ...integ, access_token: e.target.value });
          }}
          margin="dense"
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            addInteg(integ);
          }}
        >
          Add
        </Button>
        <Button onClick={props.closeDialog}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

export { IntegList };
