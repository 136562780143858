import { createEntityAdapter } from "@reduxjs/toolkit";
import {rootAPI} from "../../app/api";

enum Status {
  Waiting = "Waiting",
  Pending = "Pending",
  Running = "Running",
  Success = "Success",
  Failed = "Failed",
  Unknown = "Unknown",
}

interface Job{
  id: number;
  conf_path: string;
  address: string;
  owner: string;
  repo: string;
  commit_id: string;
  repo_id: number;
  integ_id: number;
  created_at: number;
  status: Status;
  branch: string;
  author: string;
  email: string;
  message: string;
  url: string;
  start_time: number;
  duration: number;
}

interface Log{
  id: number;
  job_id: number;
  content: Content;
  created_at: number;
}

interface Content{
  tasks: Array<Task>;
}

interface Task {
  stages: Array<Stage>;
  task_name: string;
}

interface Stage {
  name: string;
  logs: Array<Line>;
}

interface Line {
  content: string;
  time: number;
}

interface LogLine{
  line: Line;
  task_idx: number;
  task_name: string;
  stage_idx: number;
  stage_name: string;
}

const jobAdapter = createEntityAdapter<Job>();

export const jobAPI = rootAPI.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    log: builder.query<Log, number>({
      query: (jobId: number) => `job/${jobId}/log`,
      async onCacheEntryAdded(arg, {updateCachedData, cacheDataLoaded, cacheEntryRemoved}){
        // construct websocket uri
        var loc = window.location, ws_uri;
        if (loc.protocol === "https:"){
          ws_uri = "wss:";
        }else{
          ws_uri = "ws:";
        };
        ws_uri += "//" + loc.host;
        ws_uri += `/v1/job/${arg}/realtime/ws`;
        console.log(ws_uri);

        const ws = new WebSocket(ws_uri);

        try {
          await cacheDataLoaded;

          const listener = (event:MessageEvent) => {
            const data: LogLine = JSON.parse(event.data);
            updateCachedData((log) => {
              while (log.content.tasks.length < data.task_idx + 1){
                log.content.tasks.push({
                  task_name: data.task_name,
                  stages: [],
                });
              }

              let task = log.content.tasks[data.task_idx];
              while (task.stages.length < data.stage_idx + 1){
                task.stages.push({
                  name: data.stage_name,
                  logs: [],
                });
              }

              task.stages[data.stage_idx].logs.push(data.line);
            })
          };

          ws.addEventListener('message', listener);
        } catch {
          // TODO
        }

        await cacheEntryRemoved;
        ws.close();
      },
    }),
    job: builder.query<Job, number>({
      query: (jobId: number) => `job/${jobId}/detail`,
    }),
    rerun: builder.mutation<void, {jobId: number, repoId: number}>({
      query: (args) => ({
        url: `job/${args.jobId}/rerun`,
        method: 'POST'
      }),
      invalidatesTags: (_, __, args) => [{type: "Jobs", repoId: args.repoId}],
    }),
  }),
});

export const { useLogQuery, useJobQuery, useRerunMutation } = jobAPI;

export { Status, jobAdapter };
export type { Log, Job };
