import {rootAPI} from "../../app/api";

// TODO: maybe user api
export const headerAPI = rootAPI.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<any, void>({
      query: () => "user/info",
    })
  })
});

export const { useGetUserQuery } = headerAPI;
